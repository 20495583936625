$color-primary: #03355B; // prussian blue
$color-secondary: #2AA4C1;
$color-outline: #EAF3F6;
$color-accent1: #A6808C;
$color-accent2: #F19C79;

$font-default: '';
$font-logo: 'Bahnschrift';
$font-section: 'opensans-bold', sans-serif;

.App {
  text-align: center;
}

.banner {
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 30px;
  text-align: center;
}   

.banner-text { 

  h1 {
     color: $color-primary;
     font-size: 64px;
     letter-spacing: -2px;
  }      

  h3 {
     color: $color-primary;
     font-size: larger;
     margin-right: 0px;

     span, a {
        color: $color-primary;
     }
  }      
}

/* header social links */
 .social {
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);

  li {
     display: inline-block;
     margin: 0 15px;
     padding: 0;

     a { color: $color-primary; }
     a:hover { color: $color-secondary; }
  }
}

.projects {
  min-height: 120vh;
  background-color: $color-primary;
  color: white;
}

.article {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
