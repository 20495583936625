@import '../../App.scss';

#about {
    background: $color-primary;
    overflow: hidden;
    padding-top: 56px;

    h1 {
      color: $color-outline;
      font-weight: normal;
      padding-left: 24px;
   }

   h2 {
      color: $color-outline;
      font-size: medium;
      padding-left: 28px;
   }

   h3 {
      color: $color-outline;
      font-size: 18px;
      font-family: $font-section;
      text-transform: uppercase;
      letter-spacing: 3px;
      vertical-align: bottom;
   }

   p {
      color: $color-outline;
      padding: 5px;
   }

   .video {
      width: 100%;
      height: 322px;
   }
 }
 
 .badger {
   width: 120px;
   margin: 5px;
 }

 @media (max-width: 599px) {
   .badger {
     width: 40%; 
     margin: 15px;
   }
 }

 .white {
   background-color: $color-outline;
}

.testimonials {
   position: relative;
   width: 100%;
   overflow: hidden;
   // min-height: fit-content;

   h3::before {
      font-family: 'FontAwesome';
      content: "\f10d";
      padding-right: 10px;
      font-size: 72px;
      line-height: 72px;
      text-align: left;
   }

   article {
      color: $color-outline;
      margin: 20px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      text-shadow: 0px 1px 1px white;
   }

   article cite {
      display: block;
      font-size: 12px;
      font-style: normal;
      line-height: 18px;
   }
   
   article cite:before { content: "\2014 \0020"; }

   .slide {
      height: 300px;
   }

   .carousel-inner {
      padding: 0 25px;
   }
}

