@import '../../App.scss';

#games {
    background: $color-outline;
    overflow: hidden;
    padding-top: 48px;
 }
 
h3 {
    font-family: $font-section;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-weight: bold;
}

.panel {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;

    .row {
      margin: 5px;
    }
  }
 
  .motto {
    display: inline-flex;
    font-size: medium;
    font-weight: bolder;
    color: $color-primary; 
    margin: 5px 0 5px 0;     
  }

  p {
    color: $color-primary;
    padding: 5px;
 }

