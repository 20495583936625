.responsive {
  margin: 0 10px 20px 0;
}

@media (max-width: 599px) {
  .responsive {
    width: 100%; 
    margin-bottom: 10px;
  }
}

.zoomed-image {
    width: 200%;
    transform: scale(1.2); /* Adjust the scale value as needed */
    transition: transform 0.3s ease;
  }
