#sessions {
   padding: 48px 5px 5px 5px;
   background: url(../../assets/neum.jpg) no-repeat top left;
   background-size: contain !important;
}

.text {
   font-weight: bold;
   text-shadow: white 1px 1px;
}

.main {
   padding: 40px;
}

.table {
   font-size: smaller;
}

@media (max-width: 599px) {
   .carousel-item {
      // height: 440px !important;
   }
}

.video {
   width: 100%;
   min-height: 320px;
   max-height: 100%;
}