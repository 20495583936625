@import '../../App.scss';

#projects {
      background: $color-primary;
      color: $color-outline;
      padding-top: 48px;

      h1 {
            color: $color-outline;
            font-weight: normal;
            margin-left: 10px;
      }
}

