@import '../../App.scss';

footer {
    padding-top: 20px;
    color: $color-outline;
    font-family: $font-logo;
    font-size: medium;
    text-align: center;
    position: relative;
    background-color: $color-secondary;

    a {
        color: $color-outline;
        :visited { color: $color-outline; }
        :hover { color: $color-primary } 
        :focus { color: $color-primary }
    }    
 }

 /* Go To Top Button */
 .scrollup a {
     text-decoration: none;
     border: 0 none;
     display: block;
     height: 42px;
     width: 42px;
     color: $color-primary;
     border-radius: 100%;
     font-size: 42px;
     line-height: 42px;
     position: absolute;
     top: -10px;
     left: 50%;
     margin-left: -20px;

     -webkit-transition: all 0.2s ease-in-out;
     -moz-transition: all 0.2s ease-in-out;
     -o-transition: all 0.2s ease-in-out;
     -ms-transition: all 0.2s ease-in-out;
     transition: all 0.2s ease-in-out;

     :hover {
         color: $color-outline;
     }
 }

