@import '../../App.scss';

#nav-wrap {
   font-size: 14px;
   font-family: 'opensans-bold', sans-serif;
   background-color: $color-primary;
   width: 100%;
   text-transform: uppercase;
   letter-spacing: 2.5px;
   margin: 0 auto;
   z-index: 100;
   position: fixed !important;
   left: 0;
   top: 0;

   #navbar-toggle {
      box-shadow: none;
      border-color: $color-secondary;
      background-color: $color-primary;
   }

   ul, li, a {
      color: $color-outline;
      border: none;
      outline: none;
      background-color: $color-primary;
      display: flex;
      text-align: center;
   }
}

header {
   position: relative;
   width: 100%;
   background: $color-primary url(../../assets/wallboard.JPG) no-repeat top left;
   background-size: cover !important;
	-webkit-background-size: cover !important;
   text-align: center;
   overflow: hidden;

   .banner {
      display: inline-block;
      vertical-align: bottom;
      text-align: center;
      margin-top: 50px;
      padding: 20px;
   }   

   .banner-text { 
     
      h1 {
         color: $color-primary;
         font-size: 84px;
         letter-spacing: -2px;
      }      

      h3 {
         color: $color-primary;
         font-size: larger;
         margin-right: 0px;

         span, a {
            color: $color-primary;
         }
      }      

      hr {
         border-color: $color-primary;
         border-style: dashed;
         border-width: 1px;
         display: flex;
      }
   }

.header-text
{
   margin-top: 220px;
}

   .motto {
      display: inline-flex;
      max-width: 600px;
      font-size: medium;
      font-weight: bolder;
      color: $color-primary; 
      margin: 5px 0 5px 0;     
    }

    /* header social links */
     .social {
      padding: 0;
      font-size: 30px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);

      li {
         display: inline-block;
         margin: 0 15px;
         padding: 0;

         a { color: $color-primary; }
         a:hover { color: $color-secondary; }
      }
   }

   /* scrolldown link */
   .scrolldown a {
      position: absolute;
      bottom: 0px;
      left: 50%;
      margin-left: -22px;
      color: $color-secondary;
      display: block;
      height: 42px;
      width: 42px;
      font-size: 42px;
      line-height: 42px;
      color: #fff;
      border-radius: 100%;

      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;

      :hover { color: $color-secondary; }
   }

 }
  