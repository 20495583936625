@import '../../App.scss';

#contact {
   padding-left: 5px;
   background: $color-outline;
   overflow: hidden;
   padding-top: 50px;
}

.company { 
   font-family: $font-logo;
   text-align: center;
   font-size: x-large;

   div {
     display: flow;
     flex-direction: column;
     padding: 0px;
   }

   p {
    color: $color-primary;
    margin: 0;
   }

   span {
    color: $color-secondary;
   }
 }

 .title {
  font-size: 72px;
}

 .logo {
    align-content: center;
 }
 